###*
# @ngdoc object
# @name lpaDispatching.controller:LpaDispatchingCtrl
#
# @description This controller handles the main dispatching map and filtering
# all the units and stuff
#
###
class LpaDispatchingCtrl
  ### @ngInject ###
  constructor: (
    $scope,
    $state,
    $rootScope,
    $log,
    $window,
    $filter,
    $q,
    MundoSocket,
    MundoMap,
    $timeout,
    DispatchFilters,
    DispUnitStatuses,
    StatusManager,
    Incidents,
    IncidentsManager,
    _,
    UserSettings,
    $cookies,
    filters,
    AppSettings,
    uuid4,
    UiHelpers,
    RestUtils,
    LpaMapUtils,
    DisplayLabel,
    AccountSwitcher,
    UserPermissions,
    permissions,
    MapSync,
    Statuses,
    $mdDialog,
    $mdSidenav,
    ConfirmBox,
    $translate,
    FocusManager,
    debounce,
    TasksManager
    mundoConfiguration
  ) ->
    ol = $window.ol

    $scope.filters = DispatchFilters.FilterConfig
    $scope.user = $rootScope.user
    $scope.preferences = []
    $scope.originalPreferences = []
    $scope.activeDisplayLabel =
      active: DisplayLabel.getActiveKey()
    $scope.sidebarOpen = true

    @testResults = null
    @searchText = ''
    @itemSelected = null
    @statuses = {}
    @incidents = {}
    @poiOrders = {}
    @features = {}
    @dispatchUnitStatuses = {}
    @activeUnitFilters = {}
    @activeIncidentFilters = {}
    @activePOIOrderFilters = {}
    @sideNavVisible = true
    @dispatchFeatures = mundoConfiguration.dispatchFeatures

    $rootScope.userSettings =
      popups:
        values: []
      prioPopups:
        values: []

    angular.forEach DispUnitStatuses, (dus) =>
      @dispatchUnitStatuses[dus.id] = dus

    @sideNavToggle = () =>
      @sideNavVisible =! @sideNavVisible
      $timeout (=> @map.updateSize()), 50

    @handleSearch = (search) ->
      # look up and zoom to search
      @zoomToSearch(search)
      #perform the query
      @querySearch(search)

    @zoomToSearch = debounce 1000, (location) ->
      if location.length < 3
        return false
      self = @

      MundoMap.getGeocode location
      .then (result) ->
        MundoMap.zoomToLocation self.map, result, 17
      , (error) ->
        return false
      
    @querySearch = (query) ->
      # check if query isn't empty
      if angular.isUndefined query
        query = ''
      tempResults = []
      returnResults = []
      # execute the MundoMap tag search function
      @testResults = MundoMap.executeTagSearchQuery(@map, query, _.values(@features))

      if @testResults && @testResults.length > 0
        # loop all the result and add them to tempResults to show in dropdown (autocomplete)
        tempResults = (
          (
            if tResult.get '_status'
              tResult.get '_status'
            else if tResult.get '_incident'
              tResult.get '_incident'
            else tResult.get '_poiOrder'
          ) for tResult in @testResults)
        for tResult in tempResults
          returnResults.push tResult
          if tResult.unit? && tResult.unit.dispatchUnit? && tResult.unit.dispatchUnit.dispatch_unit_status_id
            dispatchUnitId = tResult.unit.dispatchUnit.dispatch_unit_status_id
            tResult.dispatchUnitStatusLabel = @dispatchUnitStatuses[dispatchUnitId].label
          else
            tResult.dispatchUnitStatusLabel = 'Inactief'
          tResult.ios = []
          for key of tResult.io
            if tResult.io.hasOwnProperty key
              tempObj = tResult.io[key]
              tResult.ios.push tempObj
    #   # Return the results to the dropdown
    #     if returnResults.length == 1
    #       @selectedItem = returnResults[0]
        return returnResults
      else
        return returnResults

    @selectedItemChange = (item) ->
      if angular.isDefined item
        # Open the 'fast track vehicle' or 'incident' window
        if item.type == 'status'
          LpaMapUtils.openFastTrackVehicleWindow item
        else if item.type == 'incident'
          LpaMapUtils.openIncidentWindow item
        else if item.type == 'poi_order'
          LpaMapUtils.openPOIOrderWindow item
        # Reset the search values
        @searchText = ''

    # Override some params/functions of MundoMap
    @map = MundoMap.createInstance 'dispatch-map',
      follow:
        objects: []
        enabled: false
        speedZoom: false
      fillScreen: true
      search:
        enabled: false
        events:
          onTagSearchQueryExecute: () ->
          onSearchQueryExecuted: (map, query, results) ->
            return results


    # Make some layers
    @vehicleLayer = MundoMap.getLayerById @map, 'markers'
    @incidentLayer = MundoMap.getLayerById @map, 'incidents'
    @anprLayer = MundoMap.getLayerById @map, 'anpr'
    # @trailLayer = MundoMap.getLayerById @map, 'trails'
    @poiOrderLayer = MundoMap.getLayerById @map, 'poiOrders'

    LpaMapUtils.setIncidentLayerStyle @incidentLayer
    LpaMapUtils.setVehicleLayerStyle @vehicleLayer
    # LpaMapUtils.setTrailsLayerStyle @trailLayer
    LpaMapUtils.setPOIOrderLayerStyle @poiOrderLayer

    # Defines a function that will toggle the filters open and closed
    # TODO: Save this information to the user/session.
    $scope.toggle = () ->
      @filterGroup.open = !@filterGroup.open

    $scope.sidebarToggle = (ev) ->
      ev.stopPropagation()
      $mdSidenav('left').toggle()
      $scope.sidebarOpen = !$scope.sidebarOpen

    # Toggling the user menu dropdown
    $scope.userToggle = (ev) ->
      ev.stopPropagation()
      @userSettings.popups.open = !@userSettings.popups.open

    @selectFeatureOnMapInteraction = new ol.interaction.Select
      condition: ol.events.condition.click
      layers: [@incidentLayer, @vehicleLayer, @anprLayer, @poiOrderLayer]
      style: (feature) ->
        style = feature.get '_cachedStyle'

    @map.addInteraction @selectFeatureOnMapInteraction
    @selectFeatureOnMapInteraction.on 'select', (e) =>
      if e.selected.length > 0
        @selectFeatureOnMap e

    @zoomMapToFeatures = (features) =>
      extent = ol.extent.createEmpty()
      angular.forEach features, (feature)->
        ol.extent.extend extent, feature.getGeometry().getExtent()

      pan = ol.animation.pan
        duration: 500
        source: @map.getView().getCenter()

      zoom = ol.animation.zoom
        duration: 500
        resolution: @map.getView().getResolution()

      @map.beforeRender(pan)
      @map.beforeRender(zoom)

      @map
        .getView()
        .fit extent, @map.getSize(),
          padding: [100, 100, 100, 100]
          constrainResolution: true

    @popupOverlay = new ol.Overlay.Popup
      insertFirst: false
    @map.addOverlay @popupOverlay

    @selectFeatureOnMap = (e) =>
      layerProps = e.target.getLayer(e.selected[0]).getProperties()
      featureProps = e.selected[0].getProperties()

      # Deselect features for reselect directly
      @selectFeatureOnMapInteraction.getFeatures().clear()

      $log.debug layerProps._layerId

      if layerProps._layerId is "markers"
        features = featureProps.features

        if features.length == 1
          LpaMapUtils.openFastTrackVehicleWindow features[0].get('_status')
        else
          @zoomMapToFeatures features

      if layerProps._layerId is "incidents"
        features = featureProps.features

        if featureProps.features.length == 1
          LpaMapUtils.openIncidentWindow features[0].get('_incident')
        else
          @zoomMapToFeatures features
      
      if layerProps._layerId is "poiOrders"
        features = featureProps.features
        
        if featureProps.features.length == 1
          LpaMapUtils.openPOIOrderWindow features[0].get('_poiOrder')
        else
          @zoomMapToFeatures features

      if layerProps._layerId is "anpr"
        LpaMapUtils.showContextMenu e, @map, $scope, @popupOverlay

    @map.getViewport().addEventListener "contextmenu", (e) =>
      LpaMapUtils.showContextMenu e, @map, $scope, @popupOverlay

    @initialLoad = () ->
      promises = []
      IncidentPromise = IncidentsManager.getFullList({'filter[]': 'closedAt,NULL'})
      IncidentPromise.then (incidents) =>
        angular.forEach incidents, (returnedIncident) =>
          LpaMapUtils.addIncidentToMap returnedIncident, @features, @incidentLayer, @incidents

        @applyIncidentFilters()

      if @dispatchFeatures? && @dispatchFeatures != 'LPA'
        POIOrderPromise = TasksManager.getFullList({
          'filter[0]': 'closedAt,NULL'
          , 'filter[1]': 'taskType.code,poi_order'
          , 'filter[2]': 'lpaPoiOrder.id,NOT_NULL'
          , 'filter[3]': 'lpaPoiOrder.closedAt,NULL',
          'sort': 'createdAt,DESC'
        })
        POIOrderPromise.then (poiOrders) =>
          angular.forEach poiOrders, (returnedPOIOrder) =>
            if returnedPOIOrder.lpaPoiOrder?
              LpaMapUtils.addPOIOrderToMap returnedPOIOrder, @features, @poiOrderLayer, @poiOrders

          @applyPOIOrderFilters()

      $scope.selectedFilters = {}

      groups = UserSettings.getGroups()
      groups.then (groups) ->
        $rootScope.userSettings.popups = groups 

      prioCodes = UserSettings.getPrioCodes()
      prioCodes.then (prioCodes) ->
        $rootScope.userSettings.prioPopups = prioCodes

      # Somehow initialise the scope selectedfilters stuff
      angular.forEach $scope.filters, (filterGroup) ->
        $scope.selectedFilters[filterGroup.machineName] = {}
        angular.forEach filterGroup.filters, (filter) ->
          if filter.multiple
            $scope.selectedFilters[filterGroup.machineName][filter.machineName] = {}
          else
            $scope.selectedFilters[filterGroup.machineName][filter.machineName] =
              selected: filter.default
      
      # Setting the default preference and zooming to it if present
      self = @
      @getPreferences().then () ->
        if $scope.preferences? and $scope.preferences[0]?
          $scope.selectedPreference = if $scope.preferences[0].value.defaultTimestamp?
          then 0
          else $scope.selectedPreference
          self.loadPreference()

      promises.push IncidentPromise
      if @dispatchFeatures? && @dispatchFeatures != 'LPA' then promises.push POIOrderPromise
      promises.push prioCodes
      promises.push groups
      promises.push IncidentsManager.preloadIncidentTypesList()

      return $q.all promises

    # get all statuses from server
    statusPromise = RestUtils.getFullList Statuses, {
      active: true
    }
    statusPromise.then (statuses) =>
      # loop the statuses
      angular.forEach statuses, (status) =>
        # loop all assets of a status
        # LpaMapUtils.addStatusToMap status, @features, @vehicleLayer, @statuses, null, null, @trailLayer
        LpaMapUtils.addStatusToMap status, @features, @vehicleLayer, @statuses
      @applyUnitFilters()

    @toggleFilter = (filterCategory) =>
      @parseActiveFilters()

      if filterCategory == "vehicle_filters"
        @applyUnitFilters()
      if filterCategory == "incident_filters"
        @applyIncidentFilters()
      if filterCategory == 'poi_order_filters'
        @applyPOIOrderFilters()

      @saveActiveData()

    # Put all the active filters in a nice little array for easier
    # handling later on.
    @parseActiveFilters = () =>
      @activeUnitFilters = {}
      @activeIncidentFilters = {}
      @activePOIOrderFilters = {}

      for filterCategory, filterGroups of $scope.selectedFilters
        # First seperate the different filters in the different categories
        for machineName, filters of filterGroups
          if filterCategory == "vehicle_filters"
            if Object.keys(filters).length > 0
              @activeUnitFilters[machineName] = []
              trueCount = 0
              for filterId, status of filters
                if status is true
                  @activeUnitFilters[machineName].push filterId
                  trueCount++
                else
                  delete @activeUnitFilters[machineName][filterId]
              if trueCount == 0
                delete @activeUnitFilters[machineName]
          if filterCategory == "incident_filters"
            if Object.keys(filters).length > 0
              @activeIncidentFilters[machineName] = []
              trueCount = 0
              for filterId, status of filters
                if status
                  @activeIncidentFilters[machineName].push filterId
                  trueCount++
                else
                  delete @activeIncidentFilters[machineName][filterId]
              if trueCount == 0
                delete @activeIncidentFilters[machineName]
          if filterCategory == "poi_order_filters"
            if Object.keys(filters).length > 0
              @activePOIOrderFilters[machineName] = []
              trueCount = 0
              for filterId, status of filters
                if status
                  @activePOIOrderFilters[machineName].push filterId
                  trueCount++
                else
                  delete @activePOIOrderFilters[machineName][filterId]
              if trueCount == 0
                delete @activePOIOrderFilters[machineName]

    @saveActiveData = () ->
      UserSettings.setLocal 'active_dispatch_data',
        filters: $scope.selectedFilters
        popups:
          group: $rootScope.userSettings.popups.values
          prio: $rootScope.userSettings.prioPopups.values

    @loadActiveData = () =>
      data = UserSettings.getLocal 'active_dispatch_data', {}
      if data? && (!angular.equals {}, data)
        if data.filters?
          $scope.selectedFilters = data.filters
        if data.popups?
          $rootScope.userSettings.popups.values = data.popups.group
          $rootScope.userSettings.prioPopups.values = data.popups.prio
        @parseActiveFilters()
        @applyUnitFilters()
        @applyIncidentFilters()
        @applyPOIOrderFilters()
      else
        $scope.selectedPreference = 0

    MapSync.units.enable
      map: @map
      markers: @features
      statuses: @statuses
      markerLayer: @vehicleLayer
      filter: @applyUnitFilters
      # trailLayer: @trailLayer
      # incidentLayer: @incidentLayer
      # trailLayer: @trailLayer

    # Actually filter all the units.
    @applyUnitFilters = () =>
      combined_filters = []
      for filterGroup, options of @activeUnitFilters

        currentFilter = _.filter $scope.filters[0].filters, (filter) ->
          filter.machineName == filterGroup
        currentFilter = currentFilter[0]
        matchGroups = []
        for option in options
          matchedStatuses = []
          searchQuery = {}
          if angular.isDefined currentFilter.match
            searchQuery = currentFilter.match option
          else
            searchQuery[filterGroup] = option
          # This searches the statuses for matching filter values
          if option is "dispatchStatus"
            matchedStatuses = _.filter @statuses, (status) ->
              return status.unit.dispatchUnit?
          else
            matchedStatuses = _.filter @statuses,
              unit:
                dispatchUnit: searchQuery

          matchedUnitIds = _.map matchedStatuses,
            _.property 'unit.id'
          matchGroups.push matchedUnitIds

        matchGroups = _.flatten matchGroups
        combined_filters.push _.uniq matchGroups

      # This AND filters all the combined filters
      visibleFeatures =  _.intersection.apply _,combined_filters

      # TODO, DONT REMOVE ALL THE LAYERS, BUT ONLY APPLY THE
      # CHANGES
      vehicleSource = MundoMap.getLayerSource @vehicleLayer
      # trailSource = MundoMap.getLayerSource @trailLayer

      vehicleSource.clear()
      # trailSource.clear()

      unitIds = if Object.keys(@activeUnitFilters).length > 0 then visibleFeatures else Object.keys(@statuses)

      for unitId in unitIds
        vehicleSource.addFeature @features[unitId]
        # trailSource.addFeature @features[unitId].get('_trail')

    # Actually filter all the units.
    @applyIncidentFilters = () =>
      combined_filters = []
      for filterGroup, options of @activeIncidentFilters
        if not options.length
          continue

        matchGroups = []

        for option in options
          matchedIncidents = []

          if filterGroup == "incidentDispatchStatus"
            dispatchedIncidents = []
            notDispatchedIncidents = []

            _.filter @incidents, (incident) ->
              dispatched = false

              if incident.activeTasks
                dispatched = true

              if dispatched
                dispatchedIncidents.push incident
              else
                notDispatchedIncidents.push incident

            matchedIncidents = switch option
              when 'dispatched' then dispatchedIncidents
              when 'not_dispatched' then notDispatchedIncidents
              else []

          else
            searchQuery = {}
            searchQuery[filterGroup] = {}
            searchQuery[filterGroup]['id'] = option
            matchedIncidents = _.filter @incidents, searchQuery

          matchedIncidentIds = _.map matchedIncidents, _.property 'id'
          matchGroups.push matchedIncidentIds

        matchGroups = _.flatten matchGroups
        combined_filters.push _.uniq matchGroups

      # This AND filters all the combined filters
      visibleIncidents =  _.intersection.apply _,combined_filters

      # TODO, DONT REMOVE ALL THE LAYERS, BUT ONLY APPLY THE
      # CHANGES
      MundoMap.getLayerSource @incidentLayer
        .clear()
      if Object.keys(@activeIncidentFilters).length > 0
        for incidentId in visibleIncidents
          MundoMap.getLayerSource @incidentLayer
            .addFeature @features[incidentId]
      else
        for incidentFeature in Object.keys(@incidents)
          MundoMap.getLayerSource @incidentLayer
            .addFeature @features[incidentFeature]

    # Actually filter all the POI Orders.
    @applyPOIOrderFilters = () =>
      combined_filters = []
      for filterGroup, options of @activePOIOrderFilters
        if not options.length
          continue

        matchGroups = []

        for option in options
          matchedPOIOrders = []
          if filterGroup == "poiDispatchUnitType"
            dispatchUnitPOIOrders = []
            unitPOIOrders = []

            _.filter @poiOrders, (poiOrder) ->
              dispatchUnitPOIOrder = false

              if poiOrder.dispatchUnit
                dispatchUnitPOIOrder = true

              if dispatchUnitPOIOrder
                dispatchUnitPOIOrders.push poiOrder
              else
                unitPOIOrders.push poiOrder

            matchedPOIOrders = switch option
              when 'dispatch_unit' then dispatchUnitPOIOrders
              when 'unit' then unitPOIOrders
              else []
          
          else if filterGroup == 'poiOrderType'
            matchedPOIOrders = _.filter @poiOrders, (poiOrder) ->
              poiOrder.lpaPoiOrder.type == option
            matchedPOIOrders = _.uniq(matchedPOIOrders, (order) ->
              order.type
            )
          else if filterGroup == 'poiOrderHideAll'
            if option is true then matchedPOIOrders = []

          else
            searchQuery = {}
            searchQuery[filterGroup] = {}
            searchQuery[filterGroup]['id'] = option
            matchedPOIOrders = _.filter @poiOrders, searchQuery

          matchedPOIOrderIds = _.map matchedPOIOrders, _.property 'id'
          matchGroups.push matchedPOIOrderIds

        matchGroups = _.flatten matchGroups
        combined_filters.push _.uniq matchGroups

      # This AND filters all the combined filters
      visiblePOIOrders =  _.intersection.apply _,combined_filters

      # TODO, DONT REMOVE ALL THE LAYERS, BUT ONLY APPLY THE
      # CHANGES
      MundoMap.getLayerSource @poiOrderLayer
        .clear()
      if Object.keys(@activePOIOrderFilters).length > 0
        for poiOrderId in visiblePOIOrders
          MundoMap.getLayerSource @poiOrderLayer
            .addFeature @features[poiOrderId]
      else
        for poiOrderFeature in Object.keys(@poiOrders)
          MundoMap.getLayerSource @poiOrderLayer
            .addFeature @features[poiOrderFeature]

    @applyPopupSettings = (popupPreferences) ->
      if !popupPreferences.group
        popupPreferences.group = {}
      $rootScope.userSettings.popups.values = popupPreferences.group
      if !popupPreferences.prio
        popupPreferences.prio = {}
      $rootScope.userSettings.prioPopups.values = popupPreferences.prio


    @getPreferences = () =>
      $scope.preferences = []
      $scope.originalPreferences = []
      currentPref = null
      if $scope.selectedPreference
        currentPref = $scope.selectedPreference

      view = @map.getView()

      # Apply the filter on the GET
      prefsPromise = RestUtils.getFullList AppSettings,
        'filter[]': [
          "tag,savedMapPreference"
        ]
        'sort': "code, ASC"

      return $q (resolve, reject) ->
        prefsPromise.then (prefs) ->
          angular.forEach prefs, (pref) ->
            pref.value = JSON.parse pref.value
            $scope.preferences.push pref
            $scope.originalPreferences.push pref
            #sort al preferences based on the timestamp of when/if it was set as default
            $scope.originalPreferences.sort(sortByTimestamp)
            $scope.preferences.sort(sortByTimestamp)

          if currentPref?
            $scope.preferenceToSelect = currentPref
          resolve()
        , (error) ->
          reject(error)

    sortByTimestamp = (a,b) ->
      t1 = if a.value.defaultTimestamp? then a.value.defaultTimestamp else 0
      t2 = if b.value.defaultTimestamp? then b.value.defaultTimestamp else 0
      return (t2 - t1)

    @saveNewPreference = () =>
      newSettings = {}
      newSettings.value = {}

      view = @map.getView()

      # Prompt a text input to name the stuff
      UiHelpers
        .promptInput
          placeholder: "Weergavenaam"
          value: ""
          title: "Weergavenaam"
          onInput: (PreferenceLabel) =>

            newSettings.value.map = {}
            newSettings.value.map.visibleLayers = MundoMap.getVisibleLayerIds @map

            newSettings.value.map.center = ol.proj.transform view.getCenter(), 'EPSG:3857', 'EPSG:4326'
            newSettings.value.map.zoom = view.getZoom()

            if PreferenceLabel.substring(0, 5) is "-TOP-"
              # Generate a unique code for the code field which is required to be unique
              PreferenceLabel = PreferenceLabel.substring(5).trim()
              newSettings.code = "000000-" + PreferenceLabel.substring(0,10).replace(/\s+/g, '-').toLowerCase() + '_'
              + uuid4.generate()
            else
              newSettings.code = PreferenceLabel.substring(0,10).replace(/\s+/g, '-').toLowerCase() + '_'
              + uuid4.generate()

            # Add a tag to group these kind of settings
            newSettings.tag = "savedMapPreference"

            #with timestamp -1 it will belowest and added to the end of the list
            newSettings.value.defaultTimestamp = -1
            newSettings.value.settingLabel = PreferenceLabel
            newSettings.value.filters = $scope.selectedFilters
            newSettings.value.popup = {}
            newSettings.value.popup.group = $rootScope.userSettings.popups.values
            newSettings.value.popup.prio = $rootScope.userSettings.prioPopups.values
            newSettings.value = JSON.stringify newSettings.value

            #set selected preference to the newely added preference
            $scope.selectedPreference =  Object.keys($scope.preferences).length

            AppSettings.post newSettings, {}, autoTenant: true
            .then () =>
              @getPreferences()

    @loadPreference = () =>
      # Load the saved preference, usually when you pick one from
      # that one funky dropdown menu.
      if $scope.selectedPreference?
        pref = angular.copy $scope.originalPreferences[$scope.selectedPreference]

        mapSettings = pref.value
        # Transform the center to use in openlayers
        center = ol.proj.transform mapSettings.map.center, 'EPSG:4326', 'EPSG:3857'

        # Set the selected filters from the saved preference
        $scope.selectedFilters = mapSettings.filters

        #check if a map type exists that is not valid (anymore), if not default maps are loaded
        validMaps = true
        valid = false

        angular.forEach @map.getLayers(), (mapLayer) ->
          if mapSettings.map.visibleLayers.indexOf(mapLayer.get '_layerId') > -1
            valid = true
          if valid is false
            validMaps = false
          else
            validMaps = true

        if validMaps
          # Set the active layers and markers
          if mapSettings.map.visibleLayers
            MundoMap.setVisibleLayers(@map, mapSettings.map.visibleLayers)

        view = @map.getView()

        # Define the nice animations for panning and zooming
        pan = ol.animation.pan
          duration: 500
          source: view.getCenter()

        zoom = ol.animation.zoom
          duration: 500
          resolution: view.getResolution()

        @map.beforeRender(pan)
        @map.beforeRender(zoom)

        # Put the map in the right place
        view.setCenter center
        view.setZoom mapSettings.map.zoom

        # Do some black magic on the filters
        @parseActiveFilters()
        # Apply the actual filters like they're supposed to
        @applyUnitFilters()
        @applyIncidentFilters()
        @applyPOIOrderFilters()
        if !pref.value.popup
          pref.value.popup = {}
        @applyPopupSettings(pref.value.popup)
        @saveActiveData()

    @updatePreference = () =>
      # Overwrite a already sorted preference by the current settings on the frontend
      pref = $scope.preferences[$scope.selectedPreference]
      request = {}
      request.value = {}

      PreferenceLabel = pref.value.settingLabel

      view = @map.getView()
      if !request.value.popup
        request.value.popup = {}
      request.value.popup.group = $rootScope.userSettings.popups.values
      request.value.popup.prio = $rootScope.userSettings.prioPopups.values

      request.value.map = {}
      request.value.map.visibleLayers = MundoMap.getVisibleLayerIds @map

      # Generate a unique code for the code field which is required to be unique
      if pref.code.substring(0, 5) is "-TOP-"
        PreferenceLabel = PreferenceLabel.substring(5).trim()
        request.code = "000000-" + PreferenceLabel.substring(0,10).replace(/\s+/g, '-').toLowerCase() + '_'
        + uuid4.generate()
      else if pref.code.substring(0,6) is "000000"
        request.code = "000000-" + PreferenceLabel.substring(0,10).replace(/\s+/g, '-').toLowerCase() + '_'
        + uuid4.generate()
      else
        request.code = PreferenceLabel.substring(0,10).replace(/\s+/g, '-').toLowerCase() + '_' + uuid4.generate()

      request.value.map.center = ol.proj.transform view.getCenter(), 'EPSG:3857', 'EPSG:4326'
      request.value.map.zoom = view.getZoom()

      request.value.settingLabel = PreferenceLabel
      request.value.filters = $scope.selectedFilters

      request.value.defaultTimestamp = pref.value.defaultTimestamp

      request.value = JSON.stringify request.value

      AppSettings.one pref.id
      .patch request
        .then (result) =>
          @getPreferences()

    #adds a timestamp to the map preference object. The latest timestamp will be the default preference
    @setDefaultPreference = () ->
      pref = $scope.preferences[$scope.selectedPreference]

      request = {}
      request.value = {}

      PreferenceLabel = pref.value.settingLabel if pref.value.settingLabel?

      # Generate a unique code for the code field which is required to be unique
      if pref.code.substring(0, 5) is "-TOP-"
        PreferenceLabel = PreferenceLabel.substring(5).trim()
        request.code = "000000-" + PreferenceLabel.substring(0,10).replace(/\s+/g, '-').toLowerCase() + '_'
        + uuid4.generate()
      else if pref.code.substring(0,6) is "000000"
        request.code = "000000-" + PreferenceLabel.substring(0,10).replace(/\s+/g, '-').toLowerCase() + '_'
        + uuid4.generate()
      else
        request.code = PreferenceLabel.substring(0,10).replace(/\s+/g, '-').toLowerCase() + '_' + uuid4.generate()

      request.value = pref.value
      request.value.defaultTimestamp = Date.now()

      request.value = JSON.stringify request.value

      $scope.selectedPreference = 0

      AppSettings.one pref.id
      .patch request
        .then (result) =>
          @getPreferences()

    @deletePreference = () ->
      pref = $scope.preferences[$scope.selectedPreference]
      this_dispatch = this
      #confirm the delete using a modal
      ConfirmBox.showConfirmDialog(
        ($filter('translate')('app.are.you.sure.you.would.like.to.delete') +
        " " + pref.value.settingLabel + '?'),
        $filter('translate')('this.action.cannot.be.undone'))
        .then(() ->
          AppSettings.one pref.id
            .remove()
              .then () ->
                $scope.selectedPreference = 0
                this_dispatch.getPreferences())

    @getDisplayLabelsOptions = () ->
      $scope.displayLabels = DisplayLabel.getOptions()

    $scope.persistDisplayLabel = () =>
      DisplayLabel.save($scope.activeDisplayLabel.active)
      LpaMapUtils.flushCaches(@features)

    @centerMap = () ->
      #$scope.selectedPreference = 0
      @loadPreference()

    $scope.loadDefault = (last, dispatch) ->
      dispatch.loadPreference() if last

    @switchAccounts = () ->
      AccountSwitcher.popup()

    @logout = () ->
      $state.go 'authentication.logout'

    @permission = (permission) ->
      return UserPermissions.check permission
    
    @newIncident = (obj) ->
      scope = obj.data.scope
      coordinates = ol.proj.transform obj.coordinate, 'EPSG:3857', 'EPSG:4326'
      
      IncidentsManager.newIncidentFromCoordinates scope, coordinates
      .then (result) ->
        # TODO - make sure this works via MapSync
        # LpaMapUtils.addIncidentToMap result, scope.features, scope.incidentLayer, scope.incidents
        # scope.applyIncidentFilters()
        $mdDialog.hide()
      
    @newPOIOrder = (obj) ->
      scope = obj.data.scope
      coordinates = ol.proj.transform obj.coordinate, 'EPSG:3857', 'EPSG:4326'

      FocusManager.newPOIOrderFromCoordinates scope, coordinates
      .then (result) ->
        # TODO - add a layer for tasks, add a MapSync for tasks
        # LpaMapUtils.addTaskToMap result, scope.taskLayer, scope.tasks
        # scope.applyTaskFilters()
        $mdDialog.hide()
    
    MapSync.incidents.enable @map, @features, @incidents, @incidentLayer,
      listen: ['incident_create', 'incident_update', 'incident_close']
      filter: @applyIncidentFilters

    if @dispatchFeatures? && @dispatchFeatures != 'LPA'
      MapSync.poiOrders.enable @map, @features, @poiOrders, @poiOrderLayer,
        listen: ['task_assign', 'task_relieve', 'task_update']
        filter: @applyPOIOrderFilters

    contextMenu = new ContextMenu
      width:150
      defaultItems: false
      popupOverlay: @popupOverlay
      items: [
        text: $filter('ucfirst')($translate.instant('app.dispatch.newIncident'))
        data:
          scope: @
        callback: @newIncident
      ,
        text: $filter('ucfirst')($translate.instant('app.dispatch.newPOIOrder'))
        data:
          scope: @
        callback: @newPOIOrder
      ]

    if @dispatchFeatures? && @dispatchFeatures != 'LPA'
      @map.addControl(contextMenu)
    
    # prevent double popup while popup is opened
    contextMenu.on 'beforeopen', (evt) ->
      if @options.popupOverlay.isOpened() == true
        contextMenu.disable()
        if contextMenu.isOpen() == true then contextMenu.close()
      else
        contextMenu.enable()

    @initialLoad().then () =>
      @getDisplayLabelsOptions()

      if !$scope.selectedPreference
        if $scope.preferenceToSelect
          $scope.selectedPreference = $scope.preferenceToSelect
        else
          @loadActiveData()
    # @getPreferences().then () =>
    #   # After selecting a preference at init time, load
    #   # active filters from local storage
    #   @loadActiveFilters()

angular
  .module 'lpaDispatching'
  .controller 'LpaDispatchingCtrl', LpaDispatchingCtrl
